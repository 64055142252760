// Features.jsx
import React from "react";
import "./Features.css";

const featuresData = [
  {
    icon: "⚡",
    title: "সুপার ফাস্ট ই-কমার্স ওয়েবসাইট",
    points: [
      "অত্যাধুনিক প্রযুক্তি দিয়ে তৈরি",
      "দ্রুত লোডিং স্পীড",
      "সুরক্ষিত প্লাটফর্ম",
    ],
  },
  {
    icon: "📈",
    title: "ডিজিটাল মার্কেটিং ফ্রেন্ডলি",
    points: ["সকল ডিজিটাল প্লাটফর্মের সাথে ইন্টিগ্রেশন", "উচ্চ কনভার্সন রেট"],
  },
  {
    icon: "🌐",
    title: "ল্যান্ডিং পেইজ ডিজাইন",
    points: ["আকর্ষণীয় ডিজাইন", "কাস্টমাইজড লেআউট", "রেসপন্সিভ ডিজাইন"],
  },
  {
    icon: "📝",
    title: "অর্ডার ম্যানেজমেন্ট সিস্টেম",
    points: ["সহজ অর্ডার ট্র্যাকিং", "ইনস্ট্যান্ট রিপোর্টিং"],
  },
  {
    icon: "📦",
    title: "প্রডাক্ট ম্যানেজমেন্ট সিস্টেম",
    points: ["স্টক ম্যানেজমেন্ট", "প্রডাক্ট আপডেট সিস্টেম"],
  },
  {
    icon: "🏷️",
    title: "অফার ম্যানেজমেন্ট সিস্টেম",
    points: ["বিভিন্ন ধরনের ডিসকাউন্ট", "কুপন কোড সাপোর্ট"],
  },
  {
    icon: "✨",
    title: "অন্যান্য আকর্ষণীয় ফিচার সমূহ",
    points: ["নতুন ইনোভেটিভ ফিচার", "ব্যবসাকে এগিয়ে নেয়ার আধুনিক সমাধান"],
  },
];

const Features = () => {
  return (
    <div className="features-container">
      <h1 className="features-heading">ফিচার সমূহ</h1>
      <div className="features-grid">
        {featuresData.map((feature, index) => (
          <div className="features-card" key={index}>
            <div className="features-card-icon">{feature.icon}</div>
            <div className="features-card-title">{feature.title}</div>
            <div className="features-card-text">
              <ul className="features-card-list">
                {feature.points.map((point, idx) => (
                  <li className="features-card-list-item" key={idx}>
                    {point}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Features;
