import { useState, useEffect } from "react";
import AxiosUrl from "../AxiosUrl";

import "./Billing.css";
import Modal from "../BillingModal/BillingModal";
import Features from "./Features";
import { styled } from "@mui/material/styles";
import FormGroup from "@mui/material/FormGroup";

import Switch, { SwitchProps } from "@mui/material/Switch";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

function Billing() {
  const uid = localStorage.getItem("id");
  const [open, setOpenModal] = useState(false);

  const [ActivePackage, setActivePackage] = useState();
  const [nextBilling, setNextBilling] = useState();
  const [Userpackage, setUserpackage] = useState();
  const [data, setData] = useState();
  const [isMonthly, setIsMonthly] = useState(true);
  const [VisitorLeft, setVisitorLeft] = useState();
  const [Unlimited, setUnlimited] = useState();

  const AntSwitch = styled(Switch)(({ theme }) => ({
    width: 28,
    height: 16,
    padding: 0,
    display: "flex",
    "&:active": {
      "& .MuiSwitch-thumb": {
        width: 15,
      },
      "& .MuiSwitch-switchBase.Mui-checked": {
        transform: "translateX(9px)",
      },
    },
    "& .MuiSwitch-switchBase": {
      padding: 2,
      "&.Mui-checked": {
        transform: "translateX(12px)",
        color: "#fff",
        "& + .MuiSwitch-track": {
          opacity: 1,
          backgroundColor:
            theme.palette.mode === "dark" ? "#177ddc" : "#1890ff",
        },
      },
    },
    "& .MuiSwitch-thumb": {
      boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
      width: 12,
      height: 12,
      borderRadius: 6,
      transition: theme.transitions.create(["width"], {
        duration: 200,
      }),
    },
    "& .MuiSwitch-track": {
      borderRadius: 16 / 2,
      opacity: 1,
      backgroundColor:
        theme.palette.mode === "dark"
          ? "rgba(255,255,255,.35)"
          : "rgba(0,0,0,.25)",
      boxSizing: "border-box",
    },
  }));

  const handleSwitchChange = () => {
    setIsMonthly((prevIsMonthly) => !prevIsMonthly);
  };

  useEffect(() => {
    AxiosUrl.get(`/get-subscription/${uid}`)
      .then((res) => {
        setActivePackage(res.data.ActivePackage);
        setUserpackage(res.data.package);
        setVisitorLeft(res.data.VisitorLeft);
        setUnlimited(res.data.Unlimited);
        const dateObject = new Date(res.data.nextBilling);

        const formattedDate = new Intl.DateTimeFormat("en-US", {
          year: "numeric",
          month: "long",
          day: "numeric",
        }).format(dateObject);
        setNextBilling(formattedDate);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <>
      <div className="main-billing">
        {open && <Modal data={data} setOpenModal={setOpenModal} />}

        {ActivePackage && (
          <div className="subscribe-package">
            ৳{Userpackage} Package Activated | Next Billing Date ➤ {""}
            {nextBilling} & Available Site Visit ➤{" "}
            {Unlimited === "1" ? "Unlimited" : VisitorLeft}
          </div>
        )}

        {/* <div className="year-month">
          <FormGroup>
            <Stack direction="row" spacing={1} alignItems="center">
              <Typography style={{ fontSize: "20px" }}>মাসিক</Typography>
              <AntSwitch
                checked={isMonthly}
                onChange={handleSwitchChange}
                inputProps={{ "aria-label": "ant design" }}
              />
              <Typography style={{ fontSize: "20px" }}>বাৎসরিক</Typography>
            </Stack>
          </FormGroup>
        </div> */}
        <div className="package">
          <div
            className="package-box"
            onClick={() => {
              setData({
                // package: isMonthly ? 3000 : 500,
                // visitor: isMonthly ? "200000" : "20000",
                package: 500,
                visitor: "unlimited",
                unlimited: "1",
                product: 0,
                year: false,
              });
              setOpenModal(true);
            }}
          >
            <h1
              style={{
                backgroundColor: "rgb(66, 82, 206)",
                color: "#fff",
                padding: "7px",
              }}
            >
              মাসিক প্যাকেজ
            </h1>
            <h1
              style={{
                backgroundColor: "rgb(64, 78, 189)",
                color: "#fff",
                padding: "9px",
              }}
            >
              {/* ৳ {isMonthly ? 3000 : 500} / {isMonthly ? "বছর" : "মাস"} */}৳
              500 মাসিক
            </h1>
            <ul>
              <li>Free Sub-Domain</li>
              <li>Unlimited Product</li>
              <li>Advance Report</li>
              <li>Pixel Setup</li>
              <li>FaceBook Conversions API</li>
              <li>Google Analytics</li>
              <li>Google Tag Manager</li>
              <li>Bkash Api</li>
              <li>WhatsApp Button</li>
              <li>Messenger Button</li>
              <li>24/7 Customer Support</li>
              <li>SMS Notification</li>
              <li>Courier Integration</li>
            </ul>

            <button
              style={{ backgroundColor: "#e2136e", color: "#fff" }}
              onClick={() => {
                setData({
                  // package: isMonthly ? 3000 : 500,
                  // visitor: isMonthly ? "200000" : "20000",
                  package: 500,
                  visitor: "unlimited",
                  unlimited: "1",
                  product: 0,
                  year: false,
                });
                setOpenModal(true);
              }}
            >
              Pay Bkash
            </button>
            <p
              style={{
                width: "83%",
                textAlign: "center",
                backgroundColor: "rgba(255, 191, 191, 0.1)",
                padding: "10px",
                borderRadius: "6px",
                marginBottom: "30px",
                border: "1px solid #ccc",
              }}
            >
              ট্রায়াল অথবা প্যাকেজের মেয়াদ শেষ হবার ৭ দিনের মধ্যে পেমেন্ট না
              করলে আপনার স্টোর অটোমেটিক ডিলিট হয়ে যাবে
            </p>
          </div>

          <div
            className="package-box"
            onClick={() => {
              setData({
                // package: isMonthly ? 24000 : 4000,
                package: 3000,
                visitor: "unlimited",
                product: 0,
                unlimited: "1",
                year: true,
              });
              setOpenModal(true);
            }}
          >
            <h1
              style={{
                backgroundColor: "rgb(14, 132, 116)",
                color: "#fff",
                padding: "7px",
              }}
            >
              বাৎসরিক প্যাকেজ
            </h1>
            <h1
              style={{
                backgroundColor: "rgb(0, 106, 92)",
                color: "#fff",
                padding: "9px",
              }}
            >
              {/* ৳ {isMonthly ? 24000 : 4000} / {isMonthly ? "বছর" : "মাস"} */}
              ৳ 3000 বছর
            </h1>
            <ul>
              <li>Free Sub-Domain</li>
              <li>Unlimited Product</li>
              <li>Advance Report</li>
              <li>Pixel Setup</li>
              <li>FaceBook Conversions API</li>
              <li>Google Analytics</li>
              <li>Google Tag Manager</li>
              <li>Bkash Api</li>
              <li>WhatsApp Button</li>
              <li>Messenger Button</li>
              <li>24/7 Customer Support</li>
              <li>SMS Notification</li>
              <li>Courier Integration</li>
            </ul>
            <button
              style={{ backgroundColor: "#e2136e", color: "#fff" }}
              onClick={() => {
                setData({
                  // package: isMonthly ? 24000 : 4000,
                  package: 3000,
                  visitor: "unlimited",
                  product: 0,
                  unlimited: "1",
                  year: true,
                });
                setOpenModal(true);
              }}
            >
              Pay Bkash
            </button>
            <p
              style={{
                width: "83%",
                textAlign: "center",
                backgroundColor: "rgba(255, 191, 191, 0.1)",
                padding: "10px",
                borderRadius: "6px",
                marginBottom: "30px",
                border: "1px solid #ccc",
              }}
            >
              ট্রায়াল অথবা প্যাকেজের মেয়াদ শেষ হবার ৭ দিনের মধ্যে পেমেন্ট না
              করলে আপনার স্টোর অটোমেটিক ডিলিট হয়ে যাবে
            </p>
          </div>
        </div>
        <Features />
      </div>
    </>
  );
}

export default Billing;
