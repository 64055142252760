import { useState, useEffect } from 'react';
import './ProductEdit.css';
import axios from 'axios';
import myAxios from '../AxiosUrl';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { EditorState, ContentState, convertFromHTML } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import { stateToHTML } from 'draft-js-export-html';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import UploadImageCdn from '../Helper/UploadImageCdn';
import MainImageSection from './MainImageSection';
import NoImage from '../../img/noinage.png';
import { useLocation, useNavigate } from 'react-router-dom';

function EditProduct() {
    const navigate = useNavigate();
    const [refresh, forceUpdate] = useState(false);
    const location = useLocation();
    console.log(location.state);
    const blocksFromHTML = convertFromHTML(location.state.productDec);
    const state = ContentState.createFromBlockArray(
        blocksFromHTML.contentBlocks,
        blocksFromHTML.entityMap
    );

    const [editorState, setEditorState] = useState(() =>
        EditorState.createWithContent(state)
    );

    const [productName, setProductName] = useState(location.state.productName);
    const [offerMessage, setOfferMessage] = useState(
        location.state.offerMessage
    );
    const [video, setVideo] = useState(location.state.video);
    const [HtmlDesc, setHtmlDesc] = useState('');
    const [buyPrice, setbuyPrice] = useState(location.state.priceBuy);
    const [sellingPrice, setsellingPrice] = useState(location.state.proceSell);
    const [compare, setCompare] = useState(location.state.compare);
    const [pCode, setpCode] = useState(location.state.pCode);
    const [colorName, setcolorName] = useState(location.state.color);
    const [quantity, setquantity] = useState(location.state.quantity);
    const [category, setCategory] = useState('');
    const [selectCategory, setSelectCategory] = useState('');
    const [selectva, setelectva] = useState(location.state.variation);
    const [productPage, setProductPage] = useState(location.state.productPage);

    const [categorydata, setCategoryData] = useState();
    const [SubCategoryData, setSubCategoryData] = useState();
    const [SubCategoryId, setSubCategoryId] = useState();

    const [loading, setLoading] = useState(false);
    const [variationColorN, setVariationColorN] = useState('');

    const [Trending, setTrendingNow] = useState(location.state.Trending);
    const [HotDeal, setHotDeal] = useState(location.state.HotDeal);

    const [RelatedProduct, setRelatedProduct] = useState(
        location.state.RelatedProduct ? location.state.RelatedProduct : 'Same'
    );

    const uid = localStorage.getItem('id');
    const [CategoryName, setCategoryName] = useState();
    const [SubCName, setSubCName] = useState();
    console.log(CategoryName, SubCName);

    const [showOrderNow, setshowOrderNow] = useState(
        location.state.showOrderNow ? location.state.showOrderNow : 'Yes'
    );

    const [images, setImages] = useState(
        location.state.images.length ? location.state.images : []
    );

    const [checkvariation, setCheckvariation] = useState(
        location.state.variation
    );

    const [rows, setRows] = useState(
        location.state.variation === 'color' ? location.state.variationData : []
    );

    const [rowsSize, setRowsSize] = useState(
        location.state.variation === 'colorSize'
            ? location.state.variationData
            : []
    );
    const [errorFields, setErrorFields] = useState({});

    const handleEditorChange = (editorState) => {
        setEditorState(editorState);
        const contentState = editorState.getCurrentContent();
        setHtmlDesc(stateToHTML(contentState));
    };

    const removeImage = (id) => {
        setImages((prevImages) => prevImages.filter((img) => img.id !== id));
    };

    const uploadCallback = (file, callback) => {
        return new Promise((resolve, reject) => {
            const reader = new window.FileReader();
            reader.onloadend = async () => {
                const form_data = new FormData();
                form_data.append('photo', file);
                const res = await UploadImageCdn(form_data);
                resolve({ data: { link: res } });
            };
            reader.readAsDataURL(file);
        });
    };
    const config = {
        image: { uploadCallback: uploadCallback },
    };

    const getCategory = () => {
        myAxios
            .get(`/category/${uid}`)
            .then((res) => {
                forceUpdate(true);
                setCategory(res.data);
                res.data.map((data) => {
                    if (data._id === location.state.category) {
                        setCategoryName(data.name);
                    }
                    if (data.subcategories) {
                        data.subcategories.map((sub) => {
                            if (sub._id === location.state.SubCategory) {
                                setSubCName(sub.name);
                            }
                        });
                    }
                });
            })
            .catch((error) => {
                console.log(error);
            });
    };

    useEffect(() => {
        getCategory();
        if (category) {
            setSelectCategory([
                { _id: '', name: 'ক্যাটাগরি সিলেক্ট করুন' },
                ...category,
            ]);
        }
    }, [refresh]);

    function addRow() {
        setRows([
            ...rows,
            {
                colorName: variationColorN,
                price: '',
                buy_price: '',
                quantity: '',
                ShipFrom: '',
                DeliveryTime: '',
                PriceCompare: '',
                image: null,
                gid: Math.floor(Math.random() * 9000000976),
            },
        ]);
        setVariationColorN('');
    }

    function handleInputChange(event, index) {
        const { name, value } = event.target;
        const updatedRows = [...rows];
        updatedRows[index][name] = value;
        setRows(updatedRows);
    }

    async function handleImageChange(event, index) {
        const image = new FormData();
        image.append('photo', event?.target?.files[0]);
        const config = {
            headers: {
                'Content-Type': 'multipart/form-data',
                token: 'zesrxdyctuutydrszrestdrxy',
            },
        };
        const url = 'https://cdn.ecombd.org/upload';
        axios
            .post(url, image, config)
            .then((res) => {
                toast.success('Image uploaded to CDN');
                const updatedRows = [...rows];
                updatedRows[index].image = res.data;
                setRows(updatedRows);
            })
            .catch((error) => {
                console.log(error);
            });
    }

    const addRowSize = () => {
        const newRow = {
            colorName: variationColorN,
            colorImage: '',
            sizes: [
                {
                    sizeName: 'S',
                    price: '',
                    quantity: '',
                    gid: Math.floor(Math.random() * 9000000976),
                },
                {
                    sizeName: 'L',
                    price: '',
                    quantity: '',
                    gid: Math.floor(Math.random() * 9000000976),
                },
                {
                    sizeName: 'M',
                    price: '',
                    quantity: '',
                    gid: Math.floor(Math.random() * 9000000976),
                },
                {
                    sizeName: 'XL',
                    price: '',
                    quantity: '',
                    gid: Math.floor(Math.random() * 9000000976),
                },
                {
                    sizeName: 'XXL',
                    price: '',
                    quantity: '',
                    gid: Math.floor(Math.random() * 9000000976),
                },
            ],
        };
        setRowsSize([...rowsSize, newRow]);
        setVariationColorN('');
    };

    const handleInputChangeSize = (event, colorIndex, sizeIndex) => {
        const { name, value } = event.target;
        const newRows = [...rowsSize];
        newRows[colorIndex].sizes[sizeIndex][name] = value;
        setRowsSize(newRows);
    };

    const handleImageChangeSize = (event, colorIndex) => {
        const image = new FormData();
        image.append('photo', event?.target?.files[0]);
        const config = {
            headers: {
                'Content-Type': 'multipart/form-data',
                token: 'zesrxdyctuutydrszrestdrxy',
            },
        };
        const url = 'https://cdn.ecombd.org/upload';
        axios
            .post(url, image, config)
            .then((res) => {
                toast.success('Image uploaded to CDN');
                const newRows = [...rowsSize];
                newRows[colorIndex].colorImage = res.data;
                setRowsSize(newRows);
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const handleRemoveSize = (colorIndex, sizeIndex) => {
        const newRows = [...rowsSize];
        newRows[colorIndex].sizes.splice(sizeIndex, 1);
        setRowsSize(newRows);
    };

    const ImageUpload = async (imgFile) => {
        const image = new FormData();
        image.append('photo', imgFile);
        const config = {
            headers: {
                'Content-Type': 'multipart/form-data',
                token: 'zesrxdyctuutydrszrestdrxy',
            },
        };
        const url = 'https://cdn.ecombd.org/upload';
        const res = await axios.post(url, image, config);

        return res.data;
    };

    const handleImageSetWrapper = async (e, imageId) => {
        const file = e.target.files[0];
        if (!file) return;
        const imgUrl = await ImageUpload(file);
        if (imageId) {
            const updatedImages = images.map((img) =>
                img.id === imageId ? { ...img, url: imgUrl } : img
            );
            setImages(updatedImages);
        } else {
            const newImage = { id: Date.now(), url: imgUrl };
            setImages([...images, newImage]);
        }
    };

    const submitH = async (e) => {
        e.preventDefault();
        setLoading(true);
        if (
            !productName ||
            !HtmlDesc ||
            !buyPrice ||
            !sellingPrice ||
            !pCode ||
            images.length === 0
        ) {
            toast.error('Please fill all the required fields');
        }
        const url = `update-product/${location.state._id}`;
        const formData1 = {
            productName,
            productDesc: HtmlDesc,
            offerMessage: offerMessage,
            buyPrice,
            sellingPrice,
            colorName,
            quantity,
            category: categorydata,
            SubCategoryId,
            uid: uid,
            variation: 'no',
            pCode,
            compare,
            images,
            showOrderNow,
            RelatedProduct,
            Trending,
            HotDeal,
            video,
        };
        const formData2 = {
            productName,
            productDesc: HtmlDesc,
            offerMessage: offerMessage,
            buyPrice,
            sellingPrice,
            category: categorydata,
            SubCategoryId,
            uid: uid,
            variation: 'color',
            variationData: rows,
            pCode,
            compare,
            productPage,
            images,
            showOrderNow,
            RelatedProduct,
            Trending,
            HotDeal,
            video,
        };
        const formData3 = {
            productName,
            productDesc: HtmlDesc,
            offerMessage: offerMessage,
            buyPrice,
            sellingPrice,
            category: categorydata,
            SubCategoryId,
            uid: uid,
            variation: 'colorSize',
            variationData: rowsSize,
            pCode,
            compare,
            images,
            showOrderNow,
            RelatedProduct,
            Trending,
            HotDeal,
            video,
        };

        let formData;
        if (selectva === 'no') {
            formData = formData1;
        }
        if (selectva === 'color') {
            formData = formData2;
        }
        if (selectva === 'colorSize') {
            formData = formData3;
        }
        myAxios
            .post(url, formData)
            .then((res) => {
                navigate('/product', { EditProductMessage: res.data.message });
            })
            .catch((error) => {
                console.log('error');
            });
    };

    function handleRemove(index) {
        const updatedRows = [...rows];
        updatedRows.splice(index, 1);
        setRows(updatedRows);
    }

    function handleRemoveColorSize(index) {
        const updatedRows = [...rowsSize];
        updatedRows.splice(index, 1);
        setRowsSize(updatedRows);
    }

    return (
        <div style={{ width: '100%' }}>
            <ToastContainer />
            <div className="AddProduct-Video">
                <div>
                    How does it work ➡️{' '}
                    <a href="#" target="_blank">
                        Video Link
                    </a>{' '}
                </div>
            </div>
            <div className="AddProduct-container">
                <div className="AddProduct-subcontainer">
                    <h3>UPDATE PRODUCT</h3>
                    <input
                        onChange={(e) => setProductName(e.target.value)}
                        type="text"
                        className={`form__input_add ${
                            errorFields.productName ? 'error-field' : ''
                        }`}
                        placeholder="Product Title"
                        value={productName}
                    />
                    <div style={{ margin: '10px' }}>
                        <div
                            style={{
                                backgroundColor: 'rgb(255, 226, 226)',
                                border: `${
                                    errorFields.HtmlDesc
                                        ? '3px solid red'
                                        : '3px solid #fff'
                                }`,
                            }}
                        >
                            <Editor
                                editorState={editorState}
                                placeholder="Write product description here"
                                onEditorStateChange={handleEditorChange}
                                toolbar={config}
                            />
                        </div>
                    </div>
                    <input
                        onChange={(e) => setVideo(e.target.value)}
                        type="text"
                        className="form__input_add"
                        placeholder="YouTube Video Link - Optional"
                        value={video}
                    />
                    <input
                        onChange={(e) => setOfferMessage(e.target.value)}
                        type="text"
                        className="form__input_add"
                        placeholder="OFFER MESSAGE LIKE: ১০০০ টাকার অর্ডারের উপরে ফ্রি ডেলিভারি"
                        value={offerMessage}
                    />
                    <input
                        onChange={(e) => setbuyPrice(e.target.value)}
                        type="number"
                        className={`form__input_add ${
                            errorFields.buyPrice ? 'error-field' : ''
                        }`}
                        placeholder="Buying Price - কেনা দাম"
                        value={buyPrice}
                    />
                    <input
                        onChange={(e) => setsellingPrice(e.target.value)}
                        type="number"
                        className={`form__input_add ${
                            errorFields.sellingPrice ? 'error-field' : ''
                        }`}
                        placeholder="Selling Price - বিক্রি দাম"
                        value={sellingPrice}
                    />
                    <input
                        onChange={(e) => setCompare(e.target.value)}
                        type="number"
                        className="form__input_add"
                        placeholder="Compare Price"
                        value={compare}
                    />
                    <input
                        onChange={(e) => setpCode(e.target.value)}
                        type="text"
                        className={`form__input_add ${
                            errorFields.pCode ? 'error-field' : ''
                        }`}
                        placeholder="Product Code CDR-001"
                        value={pCode}
                    />
                    <label>Select Variation Type</label>
                    <select
                        name="cars"
                        className="AddProduct-category"
                        onChange={(e) => {
                            setelectva(e.target.value);
                            if (e.target.value === 'color') {
                                setCheckvariation('color');
                            } else if (e.target.value === 'colorSize') {
                                setCheckvariation('colorSize');
                            } else {
                                setCheckvariation('no');
                            }
                        }}
                    >
                        <option value="no">No Variation</option>
                        <option value="color">Color</option>
                        <option value="colorSize">Color & Size</option>
                    </select>

                    {checkvariation === 'color' && (
                        <div className="AddProduct-variation">
                            <input
                                type="text"
                                className={`AddProduct-variation-input ${
                                    errorFields.variationColorN
                                        ? 'error-field'
                                        : ''
                                }`}
                                id="name"
                                placeholder="ColorName"
                                required=""
                                value={variationColorN}
                                onChange={(e) => {
                                    setVariationColorN(e.target.value);
                                }}
                            />
                            <button
                                className="AddProduct-variation-button"
                                onClick={() => {
                                    if (!variationColorN) {
                                        toast.error('কালারের নাম লিখুন..');
                                    } else {
                                        addRow();
                                    }
                                }}
                            >
                                Create Color
                            </button>
                        </div>
                    )}

                    {checkvariation === 'colorSize' && (
                        <div className="AddProduct-variation">
                            <input
                                type="text"
                                className={`AddProduct-variation-input ${
                                    errorFields.variationColorN
                                        ? 'error-field'
                                        : ''
                                }`}
                                id="name"
                                placeholder="ColorSize"
                                required=""
                                value={variationColorN}
                                onChange={(e) => {
                                    setVariationColorN(e.target.value);
                                }}
                            />
                            <button
                                className="AddProduct-variation-button"
                                onClick={() => {
                                    if (!variationColorN) {
                                        setErrorFields();
                                        toast.error('কালারের নাম লিখুন..');
                                    } else {
                                        addRowSize();
                                    }
                                }}
                            >
                                Create Color
                            </button>
                        </div>
                    )}

                    <div className="AddProduct-display-variation">
                        {rows.map((row, index) => (
                            <>
                                <div
                                    className="AddProduct-display-variation-item"
                                    key={index}
                                >
                                    <img
                                        style={{ marginRight: '5px' }}
                                        src={row.image ? row.image : NoImage}
                                        height={65}
                                        width={65}
                                    />
                                    {/* <h2 className="AddProduct-variation-item-title">
                                        {row.colorName}
                                    </h2> */}
                                    <input
                                        type="text"
                                        className="AddProduct-variation-input-price"
                                        name="colorName"
                                        placeholder="colorName"
                                        value={row.colorName}
                                        onChange={(event) =>
                                            handleInputChange(event, index)
                                        }
                                        required=""
                                    />
                                    <input
                                        type="text"
                                        className="AddProduct-variation-input-price"
                                        name="price"
                                        placeholder="Price"
                                        value={row.price}
                                        onChange={(event) =>
                                            handleInputChange(event, index)
                                        }
                                        required=""
                                    />
                                    <input
                                        type="text"
                                        className="AddProduct-variation-input-compare"
                                        name="PriceCompare"
                                        placeholder="Compare Price"
                                        value={row.PriceCompare}
                                        onChange={(event) =>
                                            handleInputChange(event, index)
                                        }
                                        required=""
                                    />
                                    <input
                                        type="text"
                                        className="AddProduct-variation-input-buy"
                                        name="buy_price"
                                        placeholder="Buying Price"
                                        value={row.buy_price}
                                        onChange={(event) =>
                                            handleInputChange(event, index)
                                        }
                                        required=""
                                    />
                                    <input
                                        type="text"
                                        className="AddProduct-variation-input-quantity"
                                        name="quantity"
                                        placeholder="Quantity"
                                        value={row.quantity}
                                        onChange={(event) =>
                                            handleInputChange(event, index)
                                        }
                                        required=""
                                    />
                                    <input
                                        type="text"
                                        className="AddProduct-variation-input-ship"
                                        name="ShipFrom"
                                        placeholder="ShipFrom"
                                        value={row.ShipFrom}
                                        onChange={(event) =>
                                            handleInputChange(event, index)
                                        }
                                        required=""
                                    />
                                    <input
                                        type="text"
                                        className="AddProduct-variation-input-time"
                                        name="DeliveryTime"
                                        placeholder="DeliveryTime"
                                        value={row.DeliveryTime}
                                        onChange={(event) =>
                                            handleInputChange(event, index)
                                        }
                                        required=""
                                    />
                                    <input
                                        type="file"
                                        className="AddProduct-variation-file"
                                        name="photo"
                                        onChange={(event) =>
                                            handleImageChange(event, index)
                                        }
                                    />
                                    <button
                                        className="AddProduct-variation-remove"
                                        onClick={() => handleRemove(index)}
                                    >
                                        X
                                    </button>
                                </div>
                            </>
                        ))}
                    </div>

                    {checkvariation === 'color' && (
                        <select
                            name="productPage"
                            className="AddProduct-category"
                            onChange={(e) => setProductPage(e.target.value)}
                        >
                            <option value="1">
                                Scroll-View-Like-FaceBook No
                            </option>
                            <option value="2">
                                Scroll-View-Like-FaceBook Yes
                            </option>
                        </select>
                    )}

                    {rowsSize.map((row, colorIndex) => {
                        return (
                            <>
                                <div
                                    className="AddProduct-ColorSizeMain"
                                    key={colorIndex}
                                >
                                    <img
                                        src={
                                            row.colorImage
                                                ? row.colorImage
                                                : NoImage
                                        }
                                        height={150}
                                        width={150}
                                    />
                                    <input
                                        type="file"
                                        className="AddProduct-file-var"
                                        name="photo"
                                        onChange={(event) =>
                                            handleImageChangeSize(
                                                event,
                                                colorIndex
                                            )
                                        }
                                    />
                                    <div
                                        className="AddProduct-remove-color-size"
                                        onClick={handleRemoveColorSize}
                                    >
                                        X
                                    </div>
                                    <div className="AddProduct-color-Size-Title">
                                        {row.colorName}
                                    </div>
                                    {row.sizes.map((size, sizeIndex) => {
                                        return (
                                            <>
                                                <div
                                                    className="AddProduct-SizeName-input"
                                                    key={sizeIndex}
                                                >
                                                    <div>
                                                        <input
                                                            type="text"
                                                            className="AddProduct-size-input"
                                                            name="sizeName"
                                                            placeholder="sizeName"
                                                            value={
                                                                size.sizeName
                                                            }
                                                            onChange={(event) =>
                                                                handleInputChangeSize(
                                                                    event,
                                                                    colorIndex,
                                                                    sizeIndex
                                                                )
                                                            }
                                                            required=""
                                                        />
                                                        <input
                                                            type="text"
                                                            className="AddProduct-size-input"
                                                            name="price"
                                                            placeholder="Price"
                                                            value={size.price}
                                                            onChange={(event) =>
                                                                handleInputChangeSize(
                                                                    event,
                                                                    colorIndex,
                                                                    sizeIndex
                                                                )
                                                            }
                                                            required=""
                                                        />
                                                        <input
                                                            type="text"
                                                            className="AddProduct-size-input"
                                                            name="quantity"
                                                            placeholder="Quantity"
                                                            value={
                                                                size.quantity
                                                            }
                                                            onChange={(event) =>
                                                                handleInputChangeSize(
                                                                    event,
                                                                    colorIndex,
                                                                    sizeIndex
                                                                )
                                                            }
                                                            required=""
                                                        />

                                                        <button
                                                            className="AddProduct-var-item-remove"
                                                            onClick={() =>
                                                                handleRemoveSize(
                                                                    colorIndex,
                                                                    sizeIndex
                                                                )
                                                            }
                                                        >
                                                            X
                                                        </button>
                                                    </div>
                                                </div>
                                            </>
                                        );
                                    })}
                                    <button
                                        style={{
                                            background: 'green',
                                            padding: '5px',
                                            color: 'white',
                                            border: '1px solid',
                                            marginTop: '10px',
                                            borderRadius: '5px',
                                            width: '100px',
                                        }}
                                        onClick={() => {
                                            const newSize = {
                                                sizeName: '',
                                                price: '',
                                                quantity: '',
                                                gid: Math.floor(
                                                    Math.random() * 9000000976
                                                ),
                                            };
                                            const updatedRows = [...rowsSize];
                                            updatedRows[colorIndex].sizes.push(
                                                newSize
                                            );
                                            setRowsSize(updatedRows);
                                        }}
                                    >
                                        Add Size
                                    </button>
                                </div>
                            </>
                        );
                    })}

                    {checkvariation === 'no' && (
                        <input
                            onChange={(e) => {
                                setcolorName(e.target.value);
                            }}
                            type="text"
                            className={`form__input_add ${
                                errorFields.colorName ? 'error-field' : ''
                            }`}
                            id="name"
                            placeholder="প্রোডাক্টের শর্ট নাম"
                            required=""
                            value={colorName}
                        />
                    )}
                    {checkvariation === 'no' && (
                        <input
                            onChange={(e) => {
                                setquantity(e.target.value);
                            }}
                            type="number"
                            className={`form__input_add ${
                                errorFields.quantity ? 'error-field' : ''
                            }`}
                            id="name"
                            placeholder="Quantity"
                            required=""
                            value={quantity}
                        />
                    )}
                    {/* SELECT CATEGORY */}
                    <label>{CategoryName} ক্যাটাগরি সিলেক্টেড</label>
                    <select
                        name="category"
                        className={`AddProduct-category ${
                            errorFields.categorydata ? 'error-field' : ''
                        }`}
                        onChange={(e) => {
                            const GetSubCategory = category.find(
                                (cat) => cat._id === e.target.value
                            );
                            setSubCategoryData(GetSubCategory.subcategories);

                            setCategoryData(e.target.value);
                        }}
                    >
                        {selectCategory &&
                            selectCategory.map((data) => {
                                return (
                                    <option
                                        value={data._id}
                                        key={data._id}
                                        defaultValue={data._id}
                                    >
                                        {data.name}
                                    </option>
                                );
                            })}
                    </select>
                    {/* SELECT CATEGORY */}

                    {/* SELECT SUB CATEGORY */}

                    <label>{SubCName} সাব ক্যাটাগরি সিলেক্টেড</label>
                    {SubCategoryData && SubCategoryData.length > 0 && (
                        <div
                            style={{
                                width: '100%',
                                display: 'flex',
                                flexDirection: 'column',
                                position: 'relative',
                            }}
                        >
                            <select
                                name="category"
                                className="AddProduct-category"
                                onChange={(e) =>
                                    setSubCategoryId(e.target.value)
                                }
                            >
                                <option value="">সাব ক্যাটাগরি - অপশনাল</option>
                                {SubCategoryData &&
                                    SubCategoryData.map((data) => {
                                        return (
                                            <option
                                                value={data._id}
                                                key={data._id}
                                            >
                                                {data.name}
                                            </option>
                                        );
                                    })}
                            </select>
                            {/* <div
                                style={{
                                    backgroundColor: 'red',
                                    position: 'absolute',
                                    width: '66px',
                                    borderRadius: '7px',
                                    right: '0',
                                    marginRight: '10px',
                                    cursor: 'pointer',
                                }}
                            >
                                Delete
                            </div> */}
                        </div>
                    )}

                    {/* SELECT SUB CATEGORY */}

                    <label>অর্ডার নাউ বাটন শো করতে চান ?</label>
                    <select
                        name="showOrderNow"
                        className="AddProduct-category"
                        value={showOrderNow}
                        defaultValue={showOrderNow}
                        onChange={(e) => setshowOrderNow(e.target.value)}
                    >
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                    </select>

                    <label>রিলেটেড প্রোডাক্ট কি হবে সিলেক্ট করুন</label>
                    <select
                        name="RelatedProduct"
                        className="AddProduct-category"
                        value={RelatedProduct}
                        defaultValue={RelatedProduct}
                        onChange={(e) => setRelatedProduct(e.target.value)}
                    >
                        <option value="Same">Same Category</option>
                        <option value="All">All Product</option>
                    </select>

                    <label>ট্রেন্ডিং নাউ</label>
                    <select
                        name="RelatedProduct"
                        className="AddProduct-category"
                        value={Trending}
                        defaultValue={Trending}
                        onChange={(e) => setTrendingNow(e.target.value)}
                    >
                        <option value="0">No</option>
                        <option value="1">Yes</option>
                    </select>

                    <label>হট ডিল</label>
                    <select
                        name="RelatedProduct"
                        className="AddProduct-category"
                        value={HotDeal}
                        defaultValue={HotDeal}
                        onChange={(e) => setHotDeal(e.target.value)}
                    >
                        <option value="0">No</option>
                        <option value="1">Yes</option>
                    </select>

                    <MainImageSection
                        images={images}
                        onImageSet={handleImageSetWrapper}
                        onRemoveImage={removeImage}
                        error={errorFields.image}
                    />

                    <button
                        onClick={submitH}
                        className="AddProduct-submit-button"
                    >
                        {loading ? 'Loading..' : 'Update Product'}
                    </button>
                </div>
            </div>
        </div>
    );
}

export default EditProduct;
